import axios from "axios";
import exchanges from "@/CurrencyProviders/CryptoMarketCap/exchanges.json";
import CcxtPrivate from "@/Ccxt/CcxtPrivate";
import { Dictionary } from "ccxt";

export interface CmcCurrency {
  id: number;
  symbol: string;
  name: string;
  slug: string;
  logoUrl?: string;
  info?: CmcCurrencyInfo;
}

export interface CmcCurrencyInfo {
  status: string;
  category: string;
  platforms: CmcPlatform[];
  urls: { website: string[] };
  description: string;
}

export interface CmcPlatform {
  contractAddress: string;
  contractChainId: number;
  contractExplorerUrl: string;
  contractId: number;
  contractNativeCurrencySymbol: string;
  contractNativeCurrencyName: string;
  contractPlatform: string;
  contractPlatformId: number;
  platformCryptoId: number;
  logoUrl?: string;
}

export class CryptoMarketCap {
  static proxy = "";
  static headers: Record<string, string> = {};

  private static map: Dictionary<string> = {
    cryptocom: "crypto-com-exchange",
    currencycom: "currency-com",
    coinbasepro: "coinbase-exchange",
    bitcoincom: "bitcoin-com-exchange",
    bitfinex2: "bitfinex",
    gateio: "gate-io",
    hitbtc3: "hitbtc",
  };
  private static currencyNameList: Dictionary<Dictionary<CmcCurrency>> = {
    // aax: {
    //   PROS: "Prosper",
    // },
    // bitfinex2: {
    //   SAND: "The Sandbox",
    //   ATLAS: "Star Atlas",
    //   LUNA: "Terra",
    // },
    bitmart: {
      //   DFA: "DeFine",
      //   H2O: "Trickle",
      ARG: { id: 0, symbol: "ARG", name: "Argonon Helium", slug: "" }, //Not found on CMC, get it from CG
    },
    bittrex: {
      //   BONDLY: "Forj(Bondly)",
      //   JAM: "Tune.FM",
      //   EDG: "Edgeware",
      YOU: { id: 15115, symbol: "YOU", name: "youves", slug: "you" },
      PROS: { id: 8008, symbol: "PROS", name: "Pros.Finance", slug: "pros-finance" },
    },
    gateio: {
      //MBX: "MARBLEX",
      GASDAO: { id: 16606, symbol: "GASDAO", name: "Gas DAO", slug: "gas-dao" },
    },
    // hitbtc3: {
    //   GST: "GrEarn",
    //   GMT: "STEPN",
    //   BUSD: "Binance USD",
    //   STETH: "Lido Staked ETH",
    //   ZSC: "Zeusshield",
    //   PERL: "PERL.eco",
    //   LUNC: "Terra Classic",
    // },
    // okx: {
    //   EURT: "Tether EURt",
    //   XAUT: "Tether Gold",
    // },
    // phemex: {
    //   STETH: "Lido Staked ETH",
    //   LUNC: "Terra Classic",
    // },
    // poloniex: {
    //   RLC: "iExec RLC",
    //   NRV: "Nerve Finance",
    // },
  };
  private static currenciesByExchange = new Map<string, Map<string, CmcCurrency>>();
  private static currenciesPromiseByExchange = new Map<string, Promise<void>>();

  static async loadData(exchange: string, ccxtExchange: CcxtPrivate) {
    const exchangeSlug = this.getExchangeSlug(exchange);

    let promise = this.currenciesPromiseByExchange.get(exchangeSlug);
    if (promise === undefined) {
      const currencies = new Map<string, CmcCurrency>();
      promise = this.getCurrenciesByExchange(currencies, exchangeSlug, ccxtExchange);
      this.currenciesPromiseByExchange.set(exchangeSlug, promise);
      await promise;
      this.currenciesPromiseByExchange.delete(exchangeSlug);
      this.currenciesByExchange.set(exchangeSlug, currencies);
    }
    return promise;
  }

  static getCurrency(exchange: string, currencyCode: string) {
    const exchangeSlug = this.getExchangeSlug(exchange);
    const currencies = this.currenciesByExchange.get(exchangeSlug);
    let currency = currencies?.get(currencyCode);

    if (currency === undefined) {
      currency = this.currencyNameList[exchange]?.[currencyCode];
      if (currency !== undefined) {
        currency.logoUrl = this.getLogoUrl(currency.id);
      }
    }
    return currency;
  }

  private static getExchangeSlug(exchange: string): string {
    const coinExchange = exchanges.values.find((exchangeData: any) => exchangeData[2] === exchange);
    if (coinExchange === undefined) {
      const mappedExchange = this.map[exchange];
      if (mappedExchange !== undefined) {
        return this.getExchangeSlug(mappedExchange);
      }
    }

    if (coinExchange === undefined) {
      throw "Exchange not found in CryptoMarketCap: " + exchange;
    }

    return coinExchange[2].toString();
  }

  private static async getCurrenciesByExchange(
    currencies: Map<string, CmcCurrency>,
    exchangeSlug: string,
    ccxtExchange: CcxtPrivate,
    category: "spot" | "perpetual" | "futures" = "spot",
    start = 1
  ) {
    const limit = 1000;
    let url = "https://api.coinmarketcap.com/data-api/v3/exchange/market-pairs/latest";
    url += `?slug=${exchangeSlug}&category=spot&start=${start}&limit=${limit}`;

    return await axios
      .get(this.proxy + url, { headers: this.getHeaders(86400) })
      .then((res) => {
        if (res.status !== 200) {
          console.error("CMC error", res.data);
          return;
        }
        const response = res.data;
        const marketPairs: any[] = response.data.marketPairs;

        for (const marketPair of marketPairs) {
          if (marketPair.category !== category) {
            continue;
          }
          const symbol = marketPair.baseSymbol;
          if (currencies.has(symbol)) {
            continue;
          }
          const currency: CmcCurrency = {
            id: marketPair.baseCurrencyId,
            symbol: symbol,
            name: marketPair.baseCurrencyName,
            slug: marketPair.baseCurrencySlug,
          };
          currency.logoUrl = this.getLogoUrl(marketPair.baseCurrencyId);
          currencies.set(symbol, currency);
        }

        if (marketPairs.length === limit) {
          return new Promise<void>((resolve, reject) => {
            setTimeout(async () => {
              try {
                await this.getCurrenciesByExchange(currencies, exchangeSlug, ccxtExchange, category, start + limit);
                resolve();
              } catch (error) {
                reject(error);
              }
            }, 1);
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  static async getCurrencies() {
    //NOTE: Old way, not active cryptos are not included, the new method below uses the source of the search fields
    //const limit = 100000;
    //let url = "https://api.coinmarketcap.com/data-api/v3/cryptocurrency/listing";
    //url += `?limit=${limit}`;
    const url = "https://s3.coinmarketcap.com/generated/core/crypto/cryptos.json";

    return await fetch(this.proxy + url, { headers: this.getHeaders(86400) }).then(async (res) => {
      const json: { values: [number, string, string, string][] } = await res.json();
      if (!res.ok) {
        console.error("CMC error", json);
        return {} as Record<string, CmcCurrency[]>;
      }
      const currencies: Record<string, CmcCurrency[]> = {};
      for (const value of json.values) {
        const currency: CmcCurrency = {
          id: value[0],
          name: value[1],
          symbol: value[2],
          slug: value[3],
        };
        currency.logoUrl = this.getLogoUrl(currency.id);
        if (currencies[currency.symbol] === undefined) {
          currencies[currency.symbol] = [];
        }
        currencies[currency.symbol].push(currency);
      }
      return currencies;
    });
  }

  static async getCurrencyInfo(currency: CmcCurrency) {
    const url = "https://coinmarketcap.com/hu/currencies/" + currency.slug + "/";
    const result = await fetch(this.proxy + url, { headers: this.getHeaders(86400) });
    const text = await result.text();
    const match = text.match(/id="__NEXT_DATA__".*?>(.*?)<\/script>/is);
    if (match === null) {
      return;
    }

    const nextData = JSON.parse(match[1]);
    const info = nextData.props.pageProps.info;
    const currencyInfo: CmcCurrencyInfo = info;
    for (const platform of currencyInfo.platforms) {
      platform.logoUrl = this.getLogoUrl(platform.platformCryptoId);
    }
    return currencyInfo;
  }

  private static getLogoUrl(id: number | undefined) {
    if (id === undefined) {
      return;
    }
    return "https://s2.coinmarketcap.com/static/img/coins/128x128/" + id + ".png";
  }

  private static getHeaders(expirationSeconds?: number) {
    if (expirationSeconds !== undefined) {
      return Object.assign({ "x-worker-cache-ttl": expirationSeconds }, this.headers);
    }
    return this.headers;
  }
}
