
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class PlatformAddress extends Vue {
  @Prop()
  platformLogo?: string;
  @Prop()
  platformName?: string;
  @Prop()
  contractAddress?: string;
  @Prop()
  explorerLink?: string;
}
