
import Vue from "vue";
import Component from "vue-class-component";
import { Currency } from "ccxt";
import CcxtPrivate from "@/service/CcxtPrivate";
import { CmcCurrency, CryptoMarketCap } from "@/CurrencyProviders/CryptoMarketCap/CryptoMarketCap";
import firebase from "@/plugins/firebase";
import { CurrencyUtils } from "@/Ccxt/CurrencyUtils";
import { CgCurrency, CoinGecko } from "@/CurrencyProviders/CoinGecko/CoinGecko";
import { BccCurrency, BlockCc } from "@/CurrencyProviders/BlockCc/BlockCc";
import PlatformAddress from "@/components/CoinChecker/PlatformAddress.vue";

@Component({
  components: { PlatformAddress },
})
export default class CoinCheckerMain extends Vue {
  exchanges = ["binance", "gateio", "kucoin", "cryptocom", "okx"];

  currencies: Record<string, Record<string, Currency>> = {};
  cmcCurrencies: Record<string, CmcCurrency[]> = {};
  cgCurrencies: Record<string, CgCurrency[]> = {};
  bccCurrencies: Record<string, BccCurrency[]> = {};

  symbolMap: Record<string, Record<string, any>> = {};

  proxy = "/cors-proxy/";
  headers = { "x-worker-cache-ttl": "86400" };

  async created() {
    await this.updateToken();

    //
    // Get symbol maps
    //
    let promises1: Promise<any>[] = [this.getBinanceSymbolMap(), this.getKucoinSymbolMap(), this.getOkxSymbolMap()];
    [this.symbolMap.binance, this.symbolMap.kucoin, this.symbolMap.okx] = await Promise.all(promises1);

    CryptoMarketCap.proxy = this.proxy;
    CryptoMarketCap.headers = this.headers;

    CoinGecko.proxy = this.proxy;
    CoinGecko.headers = this.headers;

    //
    // Load markets
    //
    let promises = [];
    const ccxtExchanges = [];
    for (const exchange of this.exchanges) {
      const ccxtExchange = new CcxtPrivate(exchange, true);
      ccxtExchanges.push(ccxtExchange);
      promises.push(ccxtExchange.ccxt.loadMarkets());
    }
    await Promise.all(promises);

    // //
    // // CMC listings
    // //
    promises = [
      CryptoMarketCap.getCurrencies(),
      CoinGecko.getCurrencies(),
      BlockCc.getCurrencies(),
      CoinGecko.getPlatforms(),
    ];
    //@ts-ignore
    [this.cmcCurrencies, this.cgCurrencies, this.bccCurrencies] = await Promise.all(promises);

    //
    // CMC exchange data, ccxt loadTransactionFeeData
    //
    promises = [];
    for (const ccxtExchange of ccxtExchanges) {
      promises.push(ccxtExchange.ccxt.loadTransactionFeeData());
      //@ts-ignore
      promises.push(CryptoMarketCap.loadData(ccxtExchange.ccxt.id, ccxtExchange));
      //@ts-ignore
      promises.push(CoinGecko.loadData(ccxtExchange.ccxt.id, ccxtExchange)); //TODO
      //@ts-ignore
      promises.push(BlockCc.loadData(ccxtExchange.ccxt.id, ccxtExchange)); //TODO
    }
    await Promise.all(promises);

    this.currencies = {};
    for (const ccxtExchange of ccxtExchanges) {
      const exchange = ccxtExchange.ccxt.id;
      const currencies = ccxtExchange.ccxt.currencies;
      const baseList = Object.values(ccxtExchange.ccxt.markets)
        .filter((market) => market.active)
        .map((market) => market.base);
      const baseSet = new Set(baseList);

      //
      // Update currencies
      //
      for (const currency of Object.values(currencies)) {
        if (exchange === "binance" && currency.info.isLegalMoney === true) {
          continue;
        }
        if (
          exchange === "gateio" &&
          currency.name !== undefined &&
          (currency.name.endsWith("3xLong") ||
            currency.name.endsWith("3xShort") ||
            currency.name.endsWith("5xLong") ||
            currency.name.endsWith("5xShort"))
        ) {
          //These tokens can not be deposited or withdraw
          continue;
        }

        if (!baseSet.has(currency.code)) {
          continue;
        }

        if (!this.currencies[currency.code]) {
          this.$set(this.currencies, currency.code, {});
          //this.currencies[currency.code] = {};
        }
        this.$set(this.currencies[currency.code], exchange, currency);
        ////@ts-ignore
        //this.currencies[currency.code][ccxtExchange.ccxt.id] = currency;

        if (exchange === "okx") {
          currency.name = this.symbolMap.okx[currency.code].fullName;
        }

        const cmcCurrency = CryptoMarketCap.getCurrency(exchange, currency.code);
        const cgCurrency = CoinGecko.getCurrency(exchange, currency.code);
        const bccCurrency = BlockCc.getCurrency(exchange, currency.code);
        currency.cmcCurrency = cmcCurrency;
        currency.cgCurrency = cgCurrency;
        currency.bccCurrency = bccCurrency;

        currency.hasSameNameAndCmcOrCgName = CurrencyUtils.hasSameNameAndCmcOrCgName(currency);
        currency.logoUrl = this.getImage(exchange, currency);
      }
    }

    //
    //Remove tokens where names are the same
    //
    // const removeCodes = [];
    // for (const [currencyCode, currencyByExchanges] of Object.entries(this.currencies)) {
    //   let hasDifferent = false;
    //   for (const [, currency] of Object.entries(currencyByExchanges)) {
    //     //@ts-ignore
    //     if (currency.hasSameNameAndCmcOrCgName !== true) {
    //       hasDifferent = true;
    //     }
    //   }
    //   if (!hasDifferent) {
    //     removeCodes.push(currencyCode);
    //   }
    // }
    // for (const removeCode of removeCodes) {
    //   this.$delete(this.currencies, removeCode);
    //   //delete this.currencies[removeCode];
    // }
  }

  getImage(exchange: string, currency: Currency) {
    if (exchange === "binance") {
      const binanceSymbol = this.symbolMap.binance[currency.code];
      if (binanceSymbol === undefined) {
        //console.log("binance", "Logo not found for", currency.code);
        return "";
      }
      if (binanceSymbol.fullName !== currency.name) {
        console.log("binance", binanceSymbol, currency);
      }
      return this.proxy + binanceSymbol.logo;
      //const projectName = this.getBinanceProjectName(currency.name);
      //return "https://research.binance.com/static/images/projects/" + projectName + "/logo.png";
    }
    if (exchange === "gateio") {
      const code = currency.code.toLowerCase();
      return "https://www.gate.io/images/coin_icon/64/" + code + ".png";
    }
    if (exchange === "cryptocom") {
      const code = currency.code;
      return "https://static2.crypto.com/exchange/token-icons/light/" + code + ".png";
    }
    if (exchange === "okx") {
      // const logoLinks = new Set();
      // for (const network of Object.values(currency.networks)) {
      //   const logoLink = network.info?.logoLink;
      //   if (logoLink !== undefined) {
      //     logoLinks.add(logoLink);
      //   }
      // }
      // if (logoLinks.size > 1) {
      //   console.log("okx", "Currency has more logoLinks", currency);
      //   return;
      // }
      // if (logoLinks.size === 0) {
      //   console.log("okx", "Logo not found for", currency.code);
      //   return "";
      // }
      // return [...logoLinks][0];
      const okxSymbol = this.symbolMap.okx[currency.code];
      if (okxSymbol === undefined) {
        //console.log("okx", "Logo not found for", currency.code);
        return "";
      }
      return okxSymbol.logo;
    }
    if (exchange === "kucoin") {
      const kucoinSymbol = this.symbolMap.kucoin[currency.code];
      if (kucoinSymbol === undefined) {
        //console.log("kucoin", "Logo not found for", currency.code);
        return "";
      }
      if (kucoinSymbol.fullName !== currency.name) {
        console.log("kucoin", kucoinSymbol, currency);
      }
      return kucoinSymbol.logo;
    }
    return "";
  }

  //async handleError(exchange: string, currency: Currency) {
  //if (exchange === "binance") {
  // const projectName = this.getBinanceProjectName(currency.name);
  // const proxy = "/cors-proxy/";
  // const url = proxy + "https://research.binance.com/en/projects/" + projectName;
  // const result = await fetch(url, { headers: CryptoMarketCap.headers });
  // const text = await result.text();
  // const match = text.match(/src="(\/static\/images\/projects\/.*?)"/i);
  // if (match !== null) {
  //   this.$set(currency, "imageUrl2", "https://research.binance.com" + match[1]);
  //   ////@ts-ignore
  //   //currency.imageUrl2 = "https://research.binance.com" + match[1];
  // }
  //}
  //}

  // getBinanceProjectName(currencyName: string) {
  //   return currencyName.toLowerCase().replaceAll(" ", "-").replaceAll(".", "");
  // }

  async getBinanceSymbolMap() {
    const result = await fetch(this.proxy + "https://www.binance.com/en/markets", { headers: this.headers });
    const text = await result.text();
    const match = text.match(/id="__APP_DATA".*?>(.*?)<\/script>/is);
    if (match === null) {
      return;
    }

    const appData = JSON.parse(match[1]);
    const symbols = appData.pageData.redux.ssrStore.symbolList.filter((symbol: any) => symbol.cmcUniqueId !== null);
    const mapped: Record<string, { cmcUniqueId: number; name: string; fullName: string; logo: string }> = {};
    for (const symbol of symbols) {
      mapped[symbol.name] = {
        cmcUniqueId: symbol.cmcUniqueId,
        name: symbol.name,
        fullName: symbol.fullName,
        logo: symbol.logo,
      };
    }
    return mapped;
  }

  async getKucoinSymbolMap() {
    const url = "https://www.kucoin.com/_api/currency/transfer-currencies?currencyType=2&flat=1&lang=en_US";
    const result = await fetch(this.proxy + url, { headers: this.headers });
    const json = await result.json();
    const data = json.data.kucoin;
    const mapped: Record<string, { name: string; fullName: string; logo: number }> = {};
    for (const item of data) {
      mapped[item.currency] = {
        name: item.currency,
        fullName: item.name,
        logo: item.iconUrl,
      };
    }
    return mapped;
  }

  async getOkxSymbolMap() {
    const url = "https://www.okx.com/v2/support/info/announce/listProject";
    const result = await fetch(this.proxy + url, { headers: this.headers });
    const json = await result.json();
    const data = json.data.list;
    const mapped: Record<string, { name: string; fullName: string; logo: number }> = {};
    for (const item of data) {
      mapped[item.project] = {
        name: item.project,
        fullName: item.fullName,
        logo: item.icon,
      };
    }
    return mapped;
  }

  async getCmcCoinInfo(cmcCurrency: CmcCurrency) {
    await this.updateToken();

    const info = await CryptoMarketCap.getCurrencyInfo(cmcCurrency);
    this.$set(cmcCurrency, "info", info);
    //cmcCurrency.info = info;
  }

  async getCgCoinInfo(cgCurrency: CgCurrency) {
    await this.updateToken();

    const info = await CoinGecko.getCurrencyInfo(cgCurrency);
    this.$set(cgCurrency, "info", info);
    //cgCurrency.info = info;
  }

  async getBccCoinInfo(bccCurrency: BccCurrency) {
    const info = await BlockCc.getCurrencyInfo(bccCurrency);
    this.$set(bccCurrency, "info", info);
    //cmcCurrency.info = info;
  }

  private async updateToken() {
    this.headers["x-auth-token"] = (await firebase.getIdToken()) ?? "";
  }

  private shortAddress(address?: string) {
    if (address === undefined || address === "") {
      return undefined;
    }
    return (address.slice(0, 5) + "..." + address.slice(-5)).toUpperCase();
  }

  private website(websites: string[] = []) {
    return websites.filter((website) => website !== "").join(", ");
  }

  get currenciesFirst100() {
    const currencies: Record<string, Record<string, Currency>> = {};
    let i = 0;
    for (const [key, item] of Object.entries(this.currencies)) {
      //if (++i > 500) {
      //  break;
      //}
      if (key !== "GASDAO") {
        continue;
      }
      currencies[key] = item;
    }
    return currencies;
  }
}
