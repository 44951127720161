import { Currency } from "ccxt";

export class CurrencyUtils {
  static hasSameBaseCurrencyName(buyBaseCurrency: Currency, sellBaseCurrency: Currency) {
    this.cleanCmcName(buyBaseCurrency);
    this.cleanCmcName(sellBaseCurrency);

    return this.isSameName(buyBaseCurrency.cache?.cmcNameCleaned, sellBaseCurrency.cache?.cmcNameCleaned, false);
  }

  static hasSameBaseCurrencyNameStrict(buyBaseCurrency: Currency, sellBaseCurrency: Currency) {
    if (buyBaseCurrency === undefined || sellBaseCurrency === undefined) {
      return false;
    }

    const buyNames = this.getNames(buyBaseCurrency);
    const sellNames = this.getNames(sellBaseCurrency);

    return buyNames.filter((x) => sellNames.includes(x)).length > 0;
  }

  private static getNames(currency: Currency) {
    const name1 = this.cleanName(currency);
    const name2 = this.cleanCmcName(currency);

    const names = [];
    if (name1 !== undefined) {
      names.push(name1);
    }
    if (name2 !== undefined) {
      names.push(name2);
    }
    return names;
  }

  static hasCurrencyName(currency: Currency, nameCleaned: string) {
    const names = [this.cleanName(currency), this.cleanCmcName(currency)];
    return names.filter((name) => name === nameCleaned).length > 0;
  }

  static hasSameNameAndCmcOrCgName(currency: Currency) {
    this.cleanName(currency);
    this.cleanCmcName(currency);
    this.cleanCgName(currency);

    return (
      this.isSameName(currency.cache?.cmcNameCleaned, currency.cache?.nameCleaned, true) ||
      this.isSameName(currency.cache?.cgNameCleaned, currency.cache?.nameCleaned, true)
    );
  }

  static getName(currency: Currency) {
    this.cleanName(currency);
    this.cleanCmcName(currency);

    const same = this.isSameName(currency.cache?.cmcNameCleaned, currency.cache?.nameCleaned, true);
    if (same) {
      return currency.cmcCurrency?.name ?? currency.name;
    }

    return (currency.cmcCurrency?.name ?? "-") + " | " + (currency.name ?? "-");
  }

  private static isSameName(name1?: string, name2?: string, strict = false) {
    if (name1 === undefined || name2 === undefined) {
      return !strict;
    }

    return name1 === name2;
  }

  private static cleanName(currency: Currency) {
    if (currency.cache?.nameCleaned !== undefined || currency.name === undefined) {
      return currency.cache?.nameCleaned;
    }

    if (currency.cache === undefined) {
      currency.cache = {};
    }
    const cleaned = this.clean(currency.name);
    currency.cache.nameCleaned = cleaned;

    return cleaned;
  }

  private static cleanCmcName(currency: Currency) {
    if (currency.cache?.cmcNameCleaned !== undefined || currency.cmcCurrency?.name === undefined) {
      return currency.cache?.cmcNameCleaned;
    }

    if (currency.cache === undefined) {
      currency.cache = {};
    }
    const cleaned = this.clean(currency.cmcCurrency.name);
    currency.cache.cmcNameCleaned = cleaned;

    return cleaned;
  }

  private static cleanCgName(currency: Currency) {
    if (currency.cache?.cgNameCleaned !== undefined || currency.cgCurrency?.name === undefined) {
      return currency.cache?.cgNameCleaned;
    }

    if (currency.cache === undefined) {
      currency.cache = {};
    }
    const cleaned = this.clean(currency.cgCurrency.name);
    currency.cache.cgNameCleaned = cleaned;

    return cleaned;
  }

  static clean(name: string) {
    // prettier-ignore
    return name
        .toLowerCase()
        .replaceAll(" ", "")
        .replaceAll(".", "")
        .replaceAll("-", "")
        .replaceAll("_", "")
        .trim();
  }
}
