import CcxtPrivate from "@/Ccxt/CcxtPrivate";
import axios from "axios";
import { Dictionary } from "ccxt";

export interface BccCurrency {
  id: string;
  name: string;
  symbol: string;
  imgUrl: string;
  info?: BccCurrencyInfo;
}

export interface BccCurrencyInfo {
  explorerUrls: string; //Comma separated
  platforms: BccPlatform[];
  websiteUrl: string;
  description?: string;
  details: { locale: string; description: string }[];
}

export interface BccPlatform {
  contractAddress: string;
  platform: string;
  explorer: string;
}

export class BlockCc {
  private static map: Dictionary<string> = {
    cryptocom: "crypto_com", //
    currencycom: "currency_com", //
    coinbasepro: "gdax",
    bitcoincom: "bitcoin_com", //
    hitbtc3: "hitbtc",
    bitfinex2: "bitfinex",
    gateio: "gate-io",
    okx: "okex",
  };

  private static token = "f0eb45adbb9056964bca96a90a1951e7";

  private static currenciesByExchange = new Map<string, Map<string, BccCurrency>>();
  private static currenciesPromiseByExchange = new Map<string, Promise<void>>();

  static async loadData(exchange: string, ccxtExchange: CcxtPrivate) {
    const exchangeSlug = this.getExchangeSlug(exchange);

    let promise = this.currenciesPromiseByExchange.get(exchangeSlug);
    if (promise === undefined) {
      const currencies = new Map<string, BccCurrency>();
      promise = this.getCurrenciesByExchange(currencies, exchangeSlug, ccxtExchange);
      this.currenciesPromiseByExchange.set(exchangeSlug, promise);
      await promise;
      this.currenciesPromiseByExchange.delete(exchangeSlug);
      this.currenciesByExchange.set(exchangeSlug, currencies);
    }
    return promise;
  }

  static getCurrency(exchange: string, currencyCode: string) {
    const exchangeSlug = this.getExchangeSlug(exchange);
    const currencies = this.currenciesByExchange.get(exchangeSlug);
    return currencies?.get(currencyCode);
  }

  static async getCurrencies() {
    const url = "https://block.cc/api/v1/quick_search/coin?t=" + this.token + "&lan=zh";

    return await fetch(url, { headers: this.getHeaders(86400) }).then(async (res) => {
      const json: { data: BccCurrency[] } = await res.json();
      if (!res.ok) {
        console.error("CG error", json);
        return {} as Record<string, BccCurrency[]>;
      }
      const currencies: Record<string, BccCurrency[]> = {};
      for (const currency of json.data) {
        if (currencies[currency.symbol] === undefined) {
          currencies[currency.symbol] = [];
        }
        currencies[currency.symbol].push(currency);
      }
      return currencies;
    });
  }

  private static async getCurrenciesByExchange(
    currencies: Map<string, BccCurrency>,
    exchangeSlug: string,
    ccxtExchange: CcxtPrivate,
    page = 1
  ) {
    const limit = 100;
    let url =
      "https://block.cc/api/new/v1/exchange/tickers?t=" +
      this.token +
      "&lan=zh&base_coin=usd&coin=&sortType=&orderby=&size=100&name=" +
      exchangeSlug;
    url += `&page=${page}`;

    return await axios
      .get(url, { headers: this.getHeaders(86400) })
      .then((res) => {
        if (res.status !== 200) {
          console.error("BCC error", res.data);
          return;
        }
        const response = res.data;
        const tickers: any[] = response.data.tickers;

        for (const ticker of tickers) {
          //We use dataCenter_pair_name (ex: GASDAO) instead of coin_symbol (ex: GAS)
          const symbol = ticker.dataCenter_pair_name.split("/")[0];
          if (currencies.has(symbol)) {
            continue;
          }
          const currency: BccCurrency = {
            id: ticker.coin_id,
            name: ticker.coin_name,
            symbol: symbol,
            imgUrl: ticker.coin_img,
          };
          currencies.set(symbol, currency);
        }

        if (tickers.length === limit) {
          return new Promise<void>((resolve, reject) => {
            setTimeout(async () => {
              try {
                await this.getCurrenciesByExchange(currencies, exchangeSlug, ccxtExchange, page + 1);
                resolve();
              } catch (error) {
                reject(error);
              }
            }, 1);
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  static async getCurrencyInfo(bccCurrency: BccCurrency) {
    const url =
      "https://data.block.cc/api/v3/symbols/" +
      bccCurrency.id +
      "?details=1&api_key=MSNMOFRWJJKKM4OV6BQ7DFE7T95QKERZWX6MMZJT";
    const result = await fetch(url, { headers: this.getHeaders(86400) });
    const json = await result.json();
    if (!result.ok) {
      console.error("BCC error", json);
      return undefined;
    }
    const bccCurrencyInfo: BccCurrencyInfo = json;
    bccCurrencyInfo.description = bccCurrencyInfo.details.find((detail) => detail.locale === "en_US")?.description;
    if (!Array.isArray(bccCurrencyInfo.platforms) && bccCurrencyInfo.platforms[""] === "") {
      //NOTE: Remove this once checked that it works
      console.error("wrong bccCurrencyInfo.platforms", bccCurrencyInfo.platforms);
      bccCurrencyInfo.platforms = [];
    }
    return bccCurrencyInfo;
  }

  private static getExchangeSlug(exchange: string): string {
    return this.map[exchange] ?? exchange;
  }

  private static getHeaders(expirationSeconds?: number) {
    if (expirationSeconds !== undefined) {
      return { "x-worker-cache-ttl": expirationSeconds.toString() } as Record<string, string>;
    }
    return {};
  }
}
